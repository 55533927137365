import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for macOS",
  "path": "/Software/macOS/InstarVision/PTZ/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Set up your video canvas area with one click in the Layout menu. Choose a layout that fits your needs and amount of active cameras. The layout will always reset to its default when you restart the software - the start-up default can be set in the System menu.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='InstarVision v3 for macOS' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/macOS/InstarVision/PTZ/' locationFR='/fr/Software/macOS/InstarVision/PTZ/' crumbLabel="PTZ" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bf437fb9a61bc7c523bde865623b341e/29114/Banner_en-InstarVision2-0_Camera-PTZ.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgklEQVQI143HsQrCMBhF4f9V9Pkc3JR2d2jiFOlUB9FXcXGtkIoPYJ0EExrTUI5gd+mFj8sRhgjBw8dD30H3huCAxNQZY1hnOaSAqKtH125kI/qW0E1CNT3Kxr8KG9neB/KNZrVcUNQOkf0TqR5I1SKHF3JyyNGNP4lnvjszKy+//gLVPsDRmm1ymQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf437fb9a61bc7c523bde865623b341e/e4706/Banner_en-InstarVision2-0_Camera-PTZ.avif 230w", "/en/static/bf437fb9a61bc7c523bde865623b341e/d1af7/Banner_en-InstarVision2-0_Camera-PTZ.avif 460w", "/en/static/bf437fb9a61bc7c523bde865623b341e/7f308/Banner_en-InstarVision2-0_Camera-PTZ.avif 920w", "/en/static/bf437fb9a61bc7c523bde865623b341e/e1c99/Banner_en-InstarVision2-0_Camera-PTZ.avif 1380w", "/en/static/bf437fb9a61bc7c523bde865623b341e/76ea5/Banner_en-InstarVision2-0_Camera-PTZ.avif 1840w", "/en/static/bf437fb9a61bc7c523bde865623b341e/f696c/Banner_en-InstarVision2-0_Camera-PTZ.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bf437fb9a61bc7c523bde865623b341e/a0b58/Banner_en-InstarVision2-0_Camera-PTZ.webp 230w", "/en/static/bf437fb9a61bc7c523bde865623b341e/bc10c/Banner_en-InstarVision2-0_Camera-PTZ.webp 460w", "/en/static/bf437fb9a61bc7c523bde865623b341e/966d8/Banner_en-InstarVision2-0_Camera-PTZ.webp 920w", "/en/static/bf437fb9a61bc7c523bde865623b341e/445df/Banner_en-InstarVision2-0_Camera-PTZ.webp 1380w", "/en/static/bf437fb9a61bc7c523bde865623b341e/78de1/Banner_en-InstarVision2-0_Camera-PTZ.webp 1840w", "/en/static/bf437fb9a61bc7c523bde865623b341e/882b9/Banner_en-InstarVision2-0_Camera-PTZ.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf437fb9a61bc7c523bde865623b341e/81c8e/Banner_en-InstarVision2-0_Camera-PTZ.png 230w", "/en/static/bf437fb9a61bc7c523bde865623b341e/08a84/Banner_en-InstarVision2-0_Camera-PTZ.png 460w", "/en/static/bf437fb9a61bc7c523bde865623b341e/c0255/Banner_en-InstarVision2-0_Camera-PTZ.png 920w", "/en/static/bf437fb9a61bc7c523bde865623b341e/b1001/Banner_en-InstarVision2-0_Camera-PTZ.png 1380w", "/en/static/bf437fb9a61bc7c523bde865623b341e/161ec/Banner_en-InstarVision2-0_Camera-PTZ.png 1840w", "/en/static/bf437fb9a61bc7c523bde865623b341e/29114/Banner_en-InstarVision2-0_Camera-PTZ.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bf437fb9a61bc7c523bde865623b341e/c0255/Banner_en-InstarVision2-0_Camera-PTZ.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "ptz-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ptz-control",
        "aria-label": "ptz control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ Control`}</h2>
    <p>{`Use the Camera PTZ menu to access your camera´s pan, tilt and zoom function as well as the preset control.`}</p>
    <p>{`The button `}<strong parentName="p">{`Left`}</strong>{` moves your camera to the Left, `}<strong parentName="p">{`Right`}</strong>{` pans it to the Right and the `}<strong parentName="p">{`Up`}</strong>{` and `}<strong parentName="p">{`Down`}</strong>{` tilt your camera Up and Down, respectively. The buttons `}<strong parentName="p">{`Zoom-in`}</strong>{` and Camera `}<strong parentName="p">{`Zoom-out`}</strong>{` activate your camera´s optical zoom - if your camera uses a varifocal lense. InstarVision also supports a digital zoom function for camera models without a zoom lense - simply select your camera´s viewport and turn your mouse wheel to zoom in and out of your camera frame. Drag & drop the image to pan & tilt your camera virtually.`}</p>
    <h2 {...{
      "id": "camera-tour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-tour",
        "aria-label": "camera tour permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Tour`}</h2>
    <p>{`Select a preset from the Preset List to move your camera to the saved preset position - the drop-down menu has to be set to `}<strong parentName="p">{`Go to Position`}</strong>{`. To save a position, move your camera to the desired position, select `}<strong parentName="p">{`Save Position`}</strong>{` from the drop-down menu and click on the number you want to assign to the position. Be careful - this function will overwrite the positions saved on the camera!`}</p>
    <p>{`Your camera´s preset function can also be used to program a Camera Patrol. Set your camera to automatically go through a list of preset positions. Select how many cycles you want the camera to move (1 - 999999999) and choose a Pause for the camera to remain at a position `}{`[in Seconds]`}{`. In the given example, your camera will go through the preset positions 1,2,3 once you clicked on `}<strong parentName="p">{`Start`}</strong>{`, starting from position 1 and stop at each position for 10 seconds. The amount of cycles will count down by one, every time your camera reaches position 3 and moves back to position 1. The patrol will automatically stop once the value for cycles equals 0. You can always stop a Patrol by clicking on `}<strong parentName="p">{`Stop`}</strong>{`.`}</p>
    <h3 {...{
      "id": "how-to-program-a-ptz-camera-tour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-program-a-ptz-camera-tour",
        "aria-label": "how to program a ptz camera tour permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to Program a PTZ Camera Tour`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/2bef9/InstarVision2_PTZ-Tour_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACi0lEQVQoz02QW0iTcRjG3337pvu+WbZK923OQ95EKRYRpa40U7sw3cGEQFKQYia6oQ62VgZ2GKapi1LIIDZr1aabs+UiO2BFJ0rcIjVM1A4OQebmRunE6T+2Kx9+F+/Nj/fhAcuztw/sb4wvP1iev9aZzc2329RXlIoGufbOjZS0tM3RWw8dzs7MFOTlHcvJyU1Pz0jakRzLodrbtdY+K0hVUkVDXbP6rLL+ZGnl8XNqaWuTsqamuLaqlM+LBQCdXu9yucbHxsdGx6anp/Py8wGgz2Ke/DEBbRequlo1Ju31RlVZtbL4VrtiUN9p03cMPDLsSU0FALv9KULI5/MtB5YRQiKxGADef3EGgggeNjV2yCq01ae65KUNFQVtiuLea7LBi6ftyvKdiXwA6O9/7PF4l5YDfr/f7XaLJBIAsL74+NMdAEtnS6eysrnuTGtlSY9a+uqyzCQ/cVdR0X1JkcCPCzW09iOEPPNzXo9nfR0VFhUBgMMxMr+KoExcmC84cDRLkJu5ryR7f3nuQbFgr0Qiqq+VJyUlA2A2m+3PPzQ0sbjg8wdXA0KRBGh0h/MrQghiKC4A0DA6DcMBw4GG0zAcwzCgYUySFaptNr/79FvX6xwampyZmBVJxOHPzpAcn5CIMyIIksUkCCYRSvggSVYUyYoCgF6jyTu3ODs5Nzw8M/trQRyWRxyOkMyPT6DjDCZBRjKJjTAJFhm1CQDuGwwIodW14MqSF638FRYJAeDb6GhIprg8AKDjDIyOb4TOiIiIJADgycAAQgitBb/7g58DqEAoCq9onZqagl27U7Ztj+FQ3FgOtREOxaN4cdFb2Cr1eWNPj+Fet+ymrrClOyPrCJvNvqrRmEzG/5ToF+ib5+dtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4f98081d7cd0e3e8ede3707202a50e4/e4706/InstarVision2_PTZ-Tour_01.avif 230w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/d1af7/InstarVision2_PTZ-Tour_01.avif 460w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/7f308/InstarVision2_PTZ-Tour_01.avif 920w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/e3723/InstarVision2_PTZ-Tour_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a4f98081d7cd0e3e8ede3707202a50e4/a0b58/InstarVision2_PTZ-Tour_01.webp 230w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/bc10c/InstarVision2_PTZ-Tour_01.webp 460w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/966d8/InstarVision2_PTZ-Tour_01.webp 920w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/a9a89/InstarVision2_PTZ-Tour_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4f98081d7cd0e3e8ede3707202a50e4/81c8e/InstarVision2_PTZ-Tour_01.png 230w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/08a84/InstarVision2_PTZ-Tour_01.png 460w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/c0255/InstarVision2_PTZ-Tour_01.png 920w", "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/2bef9/InstarVision2_PTZ-Tour_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a4f98081d7cd0e3e8ede3707202a50e4/c0255/InstarVision2_PTZ-Tour_01.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`1`}</strong>{` - Open the PTZ Menu and select Save Position from the drop-down menu underneath the PTZ control pad. Depending on your camera model 8 or 15 position slots will be available for you to program a PTZ tour.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9daeef6eb239b3dbc51391f3e477a259/2bef9/InstarVision2_PTZ-Tour_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgklEQVQoz02RX0hTcRzFz+7udHeztD+6OadGL1GaRUQ5hSwtoj+QOiF2dYIUM1GHOpiNzKwYZqkrUtAgM8rcbDppmpoF1UP2ks4USkwwX2ol7jph3rnVL2YvHj4P5+XwPYcveoffdw2+s77+0Pvq7SO7vaGtyXTTaKjRWx7cTUpJ2Ry5VZWWnqpKO5aZlZFxNFWlSkreGx0jszRb+h0O6Kp1hprKBtMlY9V5tvjMZZOusd5YVpZbUcIqFTEAOjo65ue/T09NfZ6c/DY7q1arAdjtvTMzM2i6UtLeaLZZ7tRVa0uNufebDSOdrS86Wwa6n+5LTgYwOjpKCOE4jud5Qkh+QQGACZeLEIJn9XUt5UWW0oJ2PVtTdLrJkPv8VvnI1QuDxsJdiUoAA06nx+Px+/0cx3mWlrRaLYCxsY9+fhW9rbdbjcUNlRcbi/N6TLo3N8ptevVDQ9Hj64YEZRwAp9NJCHG73RzHEUI0Gg2AT+MTP9cItNlnT6QfyjySnpV2IC/jYGHW4ez0/Tk556oq9Ik7dgLUwOBL3s8vLCwsLi7yPJ9foAUol2syVDtaHgtAQAkFFA2KhoAWUDRFURBQYkYaqt3W7vd4fvxye5e9Pp9Pw7KhzRPrm+MTEmlRGCORihlGzIS0biQSaYREGgHAYbUSQtYCgWWvN/jnL8vmAxj/H1bGJwhpkZiRhIuZjYgZqSRiE4BuqzUQCPhWV5d/u4lvhV2/PDU9HQrLYxUAhLSIEtIbEYrCwsIZAMNDQ4QQEgx+XQl+IYRdf1Wfo39ubg679yRt2x4tk8fGyOQbkckVckVcZNSW2tprfQ5HT3dXScsT9b3u4ydPRUZFmc1mm836D410HNyG3B4WAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9daeef6eb239b3dbc51391f3e477a259/e4706/InstarVision2_PTZ-Tour_02.avif 230w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/d1af7/InstarVision2_PTZ-Tour_02.avif 460w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/7f308/InstarVision2_PTZ-Tour_02.avif 920w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/e3723/InstarVision2_PTZ-Tour_02.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9daeef6eb239b3dbc51391f3e477a259/a0b58/InstarVision2_PTZ-Tour_02.webp 230w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/bc10c/InstarVision2_PTZ-Tour_02.webp 460w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/966d8/InstarVision2_PTZ-Tour_02.webp 920w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/a9a89/InstarVision2_PTZ-Tour_02.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9daeef6eb239b3dbc51391f3e477a259/81c8e/InstarVision2_PTZ-Tour_02.png 230w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/08a84/InstarVision2_PTZ-Tour_02.png 460w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/c0255/InstarVision2_PTZ-Tour_02.png 920w", "/en/static/9daeef6eb239b3dbc51391f3e477a259/2bef9/InstarVision2_PTZ-Tour_02.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9daeef6eb239b3dbc51391f3e477a259/c0255/InstarVision2_PTZ-Tour_02.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`2`}</strong>{` - Move your camera into position one of your camera tour with the PTZ control pad. Click on the 1 underneath the drop-down menu. The number will turn into a bold font for a second and the position will be saved.
InstarVision2_PTZ-Tour_03.png`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/91e22b6cc2b18deee0502d35ba6a583f/2bef9/InstarVision2_PTZ-Tour_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACfklEQVQoz02RXUhTcRjG332cbedoOj/24awF88rMLmNLSmySmrtwObV9hc6mFjlYGEWkgnQkgpJAWSStpHQZ0yPbzLKbENKr3HJCjhqkN+VKtinMnXnsH1tdCL+b5+LH+z48sPDhvYO8Y2m+0G21DNk7Ru0W6m5Ph7Gpvq6mRFEizC9UqiqUqlNVZ9WVZyqVSlXZ8XKRWDL0cGiGomB0mLTZjO1t5k6rpbfH3qXV3Gg337Rdvdysk4gKAcDpdH5fX18NBleCwa/hcKNOBwBu91QoFII3E87eW/ZBcuDBfbLV1GLR1jao1V3NDYO21mJZEQDMz88jhGKxWDIeRwgZTSYA8AcCCCH46JmZG3vqe+lcXFx0OBy93Zc6qkvHB9qumzQ5OUIA8Hq90WiUTiZja2vRlRVzy0UAWFpaopO7EHKNBFxP/BOPF8ZG3s36nM+e97fVz94znj5Znp+XBwAejwchtBmJxCIRRNN6vR4APi37f6YQ3LaYrmnPWzXqzrqKltoqXZOu/ly16oRCoZDnF4iBhXl9vmSK3tjY+L21laRpo8kMwA4EPqffFomlAMBic4HFARYbMoHFwXh8HCeyAWDW7abD335ENrfj8UQioTcY0p39mc5y+VEM4xFEFo4Tgv/gAlzAxwk8Ky1TU1OIYVJ7e/HtbWb/j8FgBIDlf/LhI3IOFxPgBF+AH0SAZxHZhwDA5ZrY22cSid34r02U2DFkLgdXV9OytEgGABwuxuZwD8LBeDw+DgBv5+YQQohh1naYLwgZMlNNUzPhcBhKj5UVFIok0iKxRHoQiVQmlRXnCvP6+vqnKeq1a/zK8IvGR67qmrpcoZAkycnJV38BLhYXxuEEHg4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91e22b6cc2b18deee0502d35ba6a583f/e4706/InstarVision2_PTZ-Tour_03.avif 230w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/d1af7/InstarVision2_PTZ-Tour_03.avif 460w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/7f308/InstarVision2_PTZ-Tour_03.avif 920w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/e3723/InstarVision2_PTZ-Tour_03.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/91e22b6cc2b18deee0502d35ba6a583f/a0b58/InstarVision2_PTZ-Tour_03.webp 230w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/bc10c/InstarVision2_PTZ-Tour_03.webp 460w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/966d8/InstarVision2_PTZ-Tour_03.webp 920w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/a9a89/InstarVision2_PTZ-Tour_03.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91e22b6cc2b18deee0502d35ba6a583f/81c8e/InstarVision2_PTZ-Tour_03.png 230w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/08a84/InstarVision2_PTZ-Tour_03.png 460w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/c0255/InstarVision2_PTZ-Tour_03.png 920w", "/en/static/91e22b6cc2b18deee0502d35ba6a583f/2bef9/InstarVision2_PTZ-Tour_03.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/91e22b6cc2b18deee0502d35ba6a583f/c0255/InstarVision2_PTZ-Tour_03.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`3`}</strong>{` - Move your camera into position two of your camera tour with the PTZ control pad. Click on the 2 underneath the drop-down menu. The number will turn into a bold font for a second and the position will be saved.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b84976e48d313ef92b6edebd846a831f/2bef9/InstarVision2_PTZ-Tour_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgUlEQVQoz02RXUhTcRyGfztnm+dslvNzc5YFEWKzELpoSzM1JS0rcTbb2VZaFswtM/yqlA2TSYW4shZCoFQ0U8yznKEIQnVjN+lKoQYN/LhxJG5rzJ3p7B9TCOG5fXnelxc02uIz5Vmt9TW62suV9Zf0LXeq9Zpm4+2auutpaekxgnipLEsqO55XUHgyN18qk0kyDicmCc1dZhtNQ6nuRNkN6Yf3Q22PmnTGc7YpR4VOd+3WFfPTttR9yQDQ19s3v7AwNzn5/dPnXy6XXC4HgKGhd06nE7T6ix13qz6+7O7p6rS0NlkHbe0vBs6ryyrqj6bsFwDAxMQEQsg7O8ssLSGEVCo1AMw4HAgheGDueG5qsxqaXxkaupvrettbLJYeReXV7JIjQnECANjtdo/HEwqFvD6fZ3VVo9EAwNTUlxATBOPDho57db33WyyN2pvVVY+bdJ2NtXqqXJaTGZcYMY+MjCCE3G63x+tFCCmVSgD4Oj2zvI5AeaGwJD9Xcba4NC9Lknbg1LHMohypoig7Q3Jwd2wcYBy7fZRZDy0uLa6srDAMo1JrADCH41uktig5BQBYLIyF4Tiby8LYgOEAGIdL8Pi7AGB0bCw0P7/sdP4JBNYCASVFRTbPbG3eszeVzeGSPD5B8qIIkiBJguQRJBlF8kh+NADQtA1tbm4Egz6/P7z5l6JUADD9P4yzOdvJnRAknxcdMfdbrRvh8FqQ8f12ozU/tWWenZvbri0GAJzNwXD2TnAOlxtFAsD4+BhCCIXDP/3hHwhR6shVw7TN5XJB+iFJfEKiUJScJBTtRCgSi8QpMYJYg8E4TNOD/W+0z17Ln/QXnC6OEQhMJtPAwNt/W7kTCXeFR/AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b84976e48d313ef92b6edebd846a831f/e4706/InstarVision2_PTZ-Tour_04.avif 230w", "/en/static/b84976e48d313ef92b6edebd846a831f/d1af7/InstarVision2_PTZ-Tour_04.avif 460w", "/en/static/b84976e48d313ef92b6edebd846a831f/7f308/InstarVision2_PTZ-Tour_04.avif 920w", "/en/static/b84976e48d313ef92b6edebd846a831f/e3723/InstarVision2_PTZ-Tour_04.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b84976e48d313ef92b6edebd846a831f/a0b58/InstarVision2_PTZ-Tour_04.webp 230w", "/en/static/b84976e48d313ef92b6edebd846a831f/bc10c/InstarVision2_PTZ-Tour_04.webp 460w", "/en/static/b84976e48d313ef92b6edebd846a831f/966d8/InstarVision2_PTZ-Tour_04.webp 920w", "/en/static/b84976e48d313ef92b6edebd846a831f/a9a89/InstarVision2_PTZ-Tour_04.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b84976e48d313ef92b6edebd846a831f/81c8e/InstarVision2_PTZ-Tour_04.png 230w", "/en/static/b84976e48d313ef92b6edebd846a831f/08a84/InstarVision2_PTZ-Tour_04.png 460w", "/en/static/b84976e48d313ef92b6edebd846a831f/c0255/InstarVision2_PTZ-Tour_04.png 920w", "/en/static/b84976e48d313ef92b6edebd846a831f/2bef9/InstarVision2_PTZ-Tour_04.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b84976e48d313ef92b6edebd846a831f/c0255/InstarVision2_PTZ-Tour_04.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`4`}</strong>{` - Move your camera into position three of your camera tour with the PTZ control pad. Click on the 3 underneath the drop-down menu. The number will turn into a bold font for a second and the position will be saved.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3958cafd86e2a488219d1039638655ef/2bef9/InstarVision2_PTZ-Tour_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACgUlEQVQoz02PW0hTcRzHfzs7053NG+VlznnpKUqZEVHQNENFIqXUhGCkedrSRF3aYDYyWoaZpc5IKYOYQ/OSx8vWlhkF1cPevLXmY1laOFG3kw/nssk/pi/++Dx8Xz58+MH4+6+vnV9GPrrGP3y2UFT7i07jA4O+Wdf1sjtdqYyKPpB95qwqK7ug4Fxebr4qK1upzIyNizebu0eGh6Cq6bq+ubHdeMNw67K6uvC2saqjzVBXV9pQo1bI4wHAYrGsrq4ueTzf3e5fy8tqtRoAxihqcXEROu/U9HW0jpqfmJrKaw2lz7r0M/299v4ex/BgZkYGAExPTyOEaJpmGAYhpNFoAGBufp7jOBhqu9dTT5prr/Tp1M3k+U596dij+pm7Gqeh4nCqAgDsNpvP52NZlqZpn8+n1WoBwOVybW1uwXjv415DdXujtqO67I2x6lNL/aju0is9aTXpUxRJADA1NYUQ8nrX/X4/QuhqZSUAzM7O8v6/UF5clK86mZujyjt9vCznREXeqWLVsZKSi40NN9PSDgFgNrud47iVld/r3nWWZTW75fmFhUCAhzhZIgAIMKEAwwHDQYALMBzDMBBgYol0t2zjOM67tkb7aYZlSJLc+zkYCEBySiouCiMkUjFBiInQ7Q6JRBohkUYAAEVRCKFgMLi5sbG9/Y8kr4XkuTme50GRnCLERWJCEi4m9iMmpJKISAAYHBzgeZ5hmD/ejc1tbk/+5nYjhECWKAcAIS7ChPh+hKKwsHACAN45naHyDtrZ+oHYnxpNSJ6YnFzyeODI0fSDsXEJssT4BNl+EmRymTwpKjrGZLrvcDhGqQnqecvbp7oLRYWR0TGtrQ+tVut/HXIdaKoKeVkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3958cafd86e2a488219d1039638655ef/e4706/InstarVision2_PTZ-Tour_05.avif 230w", "/en/static/3958cafd86e2a488219d1039638655ef/d1af7/InstarVision2_PTZ-Tour_05.avif 460w", "/en/static/3958cafd86e2a488219d1039638655ef/7f308/InstarVision2_PTZ-Tour_05.avif 920w", "/en/static/3958cafd86e2a488219d1039638655ef/e3723/InstarVision2_PTZ-Tour_05.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3958cafd86e2a488219d1039638655ef/a0b58/InstarVision2_PTZ-Tour_05.webp 230w", "/en/static/3958cafd86e2a488219d1039638655ef/bc10c/InstarVision2_PTZ-Tour_05.webp 460w", "/en/static/3958cafd86e2a488219d1039638655ef/966d8/InstarVision2_PTZ-Tour_05.webp 920w", "/en/static/3958cafd86e2a488219d1039638655ef/a9a89/InstarVision2_PTZ-Tour_05.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3958cafd86e2a488219d1039638655ef/81c8e/InstarVision2_PTZ-Tour_05.png 230w", "/en/static/3958cafd86e2a488219d1039638655ef/08a84/InstarVision2_PTZ-Tour_05.png 460w", "/en/static/3958cafd86e2a488219d1039638655ef/c0255/InstarVision2_PTZ-Tour_05.png 920w", "/en/static/3958cafd86e2a488219d1039638655ef/2bef9/InstarVision2_PTZ-Tour_05.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3958cafd86e2a488219d1039638655ef/c0255/InstarVision2_PTZ-Tour_05.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`5`}</strong>{` - Enter a number of cycles that the camera should go through for the tour (1 - 999999999), a pause for the camera to stay in each position (at least 10 seconds) and the positions you want to use for the tour - each separated by a comma (eg 1,2,3). Then click on START to start the tour.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      